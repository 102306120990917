import { Link, useLocation } from "react-router-dom";
import {
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@components/ui/sidebar";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@components/ui/collapsible";
import {
  ChevronRight,
  BadgeEuro,
  BookOpen,
  Briefcase,
  FolderCheck,
  FolderDown,
  GraduationCap,
  HandCoins,
  Hexagon,
  Landmark,
  LayoutList,
  Network,
  Send,
  UserPlus,
  BookAudio,
  Scale,
  BadgeAlert,
} from "lucide-react";
import { isDetailRoute, isListRoute, ROLE_BASED_URLS } from "src/routes";
import { ReactElement } from "react";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

interface AdminNavListItem {
  title: string;
  items: {
    title: string;
    url: string;
    icon: ReactElement;
  }[];
}

const adminNavList: AdminNavListItem[] = [
  {
    title: "Demandes de financement",
    items: [
      {
        title: "À traiter",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["fundingRequest"].list,
        icon: <FolderDown />,
      },
      {
        title: "Traitées",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["fundingRequest"].toSubmit,
        icon: <FolderCheck />,
      },
      {
        title: "Approuvées",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["fundingRequest"].approved,
        icon: <BadgeEuro />,
      },
    ],
  },
  {
    title: "Utilisateurs",
    items: [
      {
        title: "Elèves",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["student"].list,
        icon: <GraduationCap />,
      },
      {
        title: "Professeurs",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["teacher"].list,
        icon: <Briefcase />,
      },
      {
        title: "Prestataires",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["provider"].list,
        icon: <Network />,
      },
      {
        title: "Invitations",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["invitation"].list,
        icon: <Send />,
      },
      {
        title: "Demandes d'ajout d'élève",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["collaborationRequest"].list,
        icon: <UserPlus />,
      },
    ],
  },
  {
    title: "Qualité",
    items: [
      {
        title: "Cours",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["lesson"].base,
        icon: <BookOpen />,
      },
      {
        title: "Sessions presta.",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["provider"].batches,
        icon: <Network />,
      },
      {
        title: "Suivi qualité",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["trainingFollowUp"].list,
        icon: <Hexagon />,
      },
      {
        title: "Formations OPERA Off",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["internalTraining"].list,
        icon: <BookAudio />,
      },
      {
        title: "Formations prestataire",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["provider"].trainings,
        icon: <LayoutList />,
      },
    ],
  },
  {
    title: "Comptabilité",
    items: [
      {
        title: "Ajustement de solde",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["balanceAdjustments"].base,
        icon: <Scale />,
      },
      {
        title: "Paiements",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["payment"].list,
        icon: <HandCoins />,
      },
      {
        title: "Paiements des anciennes conv.",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["transfer"].convention,
        icon: <Landmark />,
      },
    ],
  },
  {
    title: "Autres",
    items: [
      {
        title: "Signalements",
        url: ROLE_BASED_URLS[ROLES.ADMIN]["issue"].base,
        icon: <BadgeAlert />,
      },
    ],
  },
];

export const AdminSideBar = () => {
  const location = useLocation();
  const { state, isMobile, toggleSidebar } = useSidebar();

  const collapsibleProps =
    state === "collapsed"
      ? {
          disable: true,
          open: true,
        }
      : {};

  return (
    <>
      {adminNavList.map(route => (
        <Collapsible key={route.title} title={route.title} defaultOpen className="group/collapsible" {...collapsibleProps}>
          <SidebarGroup>
            <SidebarGroupLabel
              asChild
              className="group/label bg-slate-100 text-sm text-sidebar-foreground hover:bg-sidebar-accent hover:text-sidebar-accent-foreground dark:bg-inherit"
            >
              <CollapsibleTrigger>
                {route.title} <ChevronRight className="ml-auto transition-transform group-data-[state=open]/collapsible:rotate-90" />
              </CollapsibleTrigger>
            </SidebarGroupLabel>
            <CollapsibleContent>
              <SidebarGroupContent>
                <SidebarMenu>
                  {route.items.map(item => {
                    // Generic approach to determine if an item should be active
                    let isActive = false;

                    // For list routes, check if we're on a detail page
                    if (isListRoute(item.url)) {
                      if (location.pathname === item.url) {
                        // Exact match
                        isActive = true;
                      } else if (isDetailRoute(location.pathname, item.url)) {
                        // We're on a detail page for this list
                        isActive = true;
                      }
                    }
                    // For all other routes, check for exact match
                    else {
                      isActive = location.pathname === item.url;
                    }

                    return (
                      <SidebarMenuItem key={item.title}>
                        <SidebarMenuButton asChild isActive={isActive} tooltip={item.title} hidden={false}>
                          <Link
                            to={item.url}
                            onClick={() => {
                              if (isMobile) toggleSidebar();
                            }}
                          >
                            {item.icon} <span>{item.title}</span>
                          </Link>
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    );
                  })}
                </SidebarMenu>
              </SidebarGroupContent>
            </CollapsibleContent>
          </SidebarGroup>
        </Collapsible>
      ))}
    </>
  );
};
