import { TokenStorageGateway } from "@shared-kernel/application/ports/token-storage-gateway";
import { HttpGateway } from "@shared-kernel/secondary/http-gateway";
import config from "../../../../../../config/backend";
import {
  ConfirmBatchEnrollmentBody,
  CreateProviderBatchBody,
  CreateProviderTrainingBody,
  isStudentBalanceSufficientForBatchEnrollmentBody,
  ProviderRepository,
  ProviderStudentEnrollmentToBatchBody,
  SubmitBatchParticipationInvoiceBody,
  UpdateProviderTrainingBody,
} from "@user-management-context/shared/application/ports/provider-repository";
import { CancelBatchEnrollmentCommand } from "@academy-context/primary/provider/provider-batches/cancel-batch-enrollment-modal";
import { Provider } from "@user-management-context/read/domain/types/provider/provider";
import { UpdateProviderProfileBody } from "@user-management-context/write/domain/types/provider/provider";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";

// TODO: Move all repos to something shared
export class BackendProviderRepository extends HttpGateway implements ProviderRepository {
  private providerRoute: string = "v1/provider";

  constructor(tokenStorageGateway: TokenStorageGateway) {
    super(config.url, tokenStorageGateway);
  }

  async profile(): Promise<Provider> {
    const { data: provider } = await this._instance.get(`${this.providerRoute}/profile`);
    return provider;
  }

  async updateProfile(profile: UpdateProviderProfileBody): Promise<void> {
    await this._instance.post(`${this.providerRoute}/profile`, profile);
  }

  async trainingList(): Promise<ProviderTraining[]> {
    const { data: trainings } = await this._instance.get(`${this.providerRoute}/trainings`);
    return trainings;
  }

  async createTraining(training: CreateProviderTrainingBody): Promise<string> {
    return this._instance.put(`${this.providerRoute}/trainings`, training);
  }

  async updateTraining(training: UpdateProviderTrainingBody): Promise<void> {
    await this._instance.post(`${this.providerRoute}/trainings/${training.trainingId}`, training);
  }

  async deleteTraining(trainingId: string): Promise<void> {
    await this._instance.delete(`${this.providerRoute}/trainings/${trainingId}`);
  }

  async batchList(): Promise<ProviderBatchItem[]> {
    const { data: batches } = await this._instance.get(`${this.providerRoute}/batches`);
    return batches;
  }

  async createBatch(batch: CreateProviderBatchBody): Promise<void> {
    return this._instance.put(`${this.providerRoute}/trainings/${batch.trainingId}/batches`, batch);
  }

  async enrollStudentToBatch(enrollment: ProviderStudentEnrollmentToBatchBody): Promise<void> {
    await this._instance.put(`${this.providerRoute}/enrollments`, enrollment);
  }

  async confirmEnrollment(enrollment: ConfirmBatchEnrollmentBody): Promise<void> {
    await this._instance.post(`${this.providerRoute}/enrollments/${enrollment.enrollmentId}:confirm`, enrollment);
  }

  async uploadBatchParticipationInvoice(invoice: File): Promise<string> {
    const formDataBody = new FormData();
    formDataBody.append("invoice", invoice);

    const res = await this._instance.post(`${this.providerRoute}/participations/invoices`, formDataBody);
    return res.data;
  }

  async submitBatchParticipationInvoice(invoice: SubmitBatchParticipationInvoiceBody): Promise<void> {
    await this._instance.post(`${this.providerRoute}/participations/${invoice.participationId}:submit-invoice`, invoice);
  }

  async isStudentBalanceSufficientForBatchEnrollment(body: isStudentBalanceSufficientForBatchEnrollmentBody): Promise<boolean> {
    const {
      data: { isStudentBalanceSufficientForBatchEnrollment },
    } = await this._instance.post(`${this.providerRoute}/students:check-balance`, body);
    return isStudentBalanceSufficientForBatchEnrollment;
  }

  async cancelEnrollment(command: CancelBatchEnrollmentCommand): Promise<void> {
    await this._instance.post(`${this.providerRoute}/enrollments/${command.enrollmentId}:cancel`, command);
  }
}
