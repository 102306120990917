import { SURVEY_QUESTION_TYPE } from "@academy-context/shared/domain/types/enums/survey";
import { useFormContext } from "react-hook-form";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Textarea } from "@components/ui/textarea";
import { Rating } from "@shared-kernel/primary/shared/rating/rating";
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card";
import { useEffect } from "react";
import { RequestState } from "@redux/app-state";
import { TrainingFollowUpSurveyVM } from "@academy-context/read/domain/types/shared/training-follow-up-survey";

interface Props {
  surveyData: TrainingFollowUpSurveyVM[];
  fetching: RequestState;
}

export const SurveyQuestionsComponent = ({ surveyData, fetching }: Props) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (surveyData.length > 0) {
      setValue(
        "surveyAnswers",
        surveyData.map(item => ({
          questionId: item.question.id,
          questionType: item.question.type,
          value: item.response ?? (item.question.type === SURVEY_QUESTION_TYPE.RATING ? item.question.options?.max : ""),
        }))
      );
    }
  }, [setValue, surveyData]);

  if (fetching === "pending") {
    return <div>Loading...</div>;
  }

  return (
    <Card className="w-full">
      <CardHeader>
        <CardTitle>Questionnaire post-formation</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        {surveyData.map((item, index) => {
          const isAnswered = Boolean(item.response);
          return (
            <FormField
              key={item.question.id}
              control={control}
              name={`surveyAnswers.${index}.value`}
              render={({ field }) => (
                <FormItem className="flex flex-col space-y-2">
                  <FormLabel className="text-sm font-normal">{item.question.text}</FormLabel>
                  <FormControl>
                    {item.question.type === SURVEY_QUESTION_TYPE.RATING ? (
                      // TODO: Add options
                      <Rating
                        value={Number(field.value) || 0}
                        onValueChange={(value: number) => {
                          field.onChange(value);
                        }}
                        disabled={isAnswered}
                      />
                    ) : (
                      <Textarea placeholder="Votre réponse..." className="resize-none" {...field} disabled={isAnswered} />
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          );
        })}
      </CardContent>
    </Card>
  );
};
