import { useEffect } from "react";
import { useAppDispatch } from "@redux/hooks";
import { useForm } from "react-hook-form";
import {
  ProviderPaymentApprovalFormInputs,
  providerPaymentApprovalSchema,
  providerPaymentApprovalDefaultValues,
  formatProviderPaymentApprovalFormDataToBodyData,
  formatProviderPaymentApprovalDataToFormData,
} from "./form-validation/provider-payment/provider-payment-approval";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProviderBatchParticipationPaymentRow } from "@academy-context/read/application/use-cases/admin/provider-batch-participation-payment-list-retrieval/selectors/provider-batch-participation-payment-list-selectors";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@components/ui/form";
import { approveProviderPayment } from "@academy-context/write/application/use-cases/admin/provider-payment-approval/approve-provider-payment";
import { Input } from "@components/ui/input";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { ProviderPaymentInformationHeader } from "@academy-context/primary/admin/shared/components/transfer/payment-information-header";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter } from "@components/ui/alert-dialog";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { OrganisationOptions } from "@shared-kernel/domain/organisation";
import { commonAdapters } from "src/common-adapters";

interface Props {
  payment: ProviderBatchParticipationPaymentRow;
  isOpen: boolean;
  onClose: () => void;
}

export const ApproveProviderPaymentModal = ({ payment, isOpen, onClose }: Props) => {
  const { dateProvider } = commonAdapters;
  const now = dateProvider!.now();

  const dispatch = useAppDispatch();
  const form = useForm<ProviderPaymentApprovalFormInputs>({
    resolver: yupResolver(providerPaymentApprovalSchema),
    defaultValues: providerPaymentApprovalDefaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = form;

  const handleOnSubmit = async (formBody: ProviderPaymentApprovalFormInputs) => {
    onClose();
    const body = formatProviderPaymentApprovalFormDataToBodyData(formBody, payment);
    dispatch(approveProviderPayment(body));
  };

  useEffect(() => {
    reset(formatProviderPaymentApprovalDataToFormData(payment));
  }, [payment, reset]);

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="min-w-[600px]">
        <ProviderPaymentInformationHeader payment={payment} />
        <Form {...form}>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="amountPaid"
                render={({ field }) => (
                  <FormItem className="col-span-3 flex flex-col">
                    <FormLabel>Montant versé</FormLabel>
                    <FormControl>
                      <Input type="number" min={0} {...field} disabled />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="organization"
                render={({ field }) => (
                  <FormItem className="col-span-3 flex flex-col">
                    <FormLabel>Société</FormLabel>
                    <FormControl>
                      <Combobox
                        options={OrganisationOptions}
                        value={field.value ?? ""}
                        onChange={value => field.onChange(value)}
                        placeholder="Selectionner la société ..."
                        search={{
                          notFoundText: "Pas de société trouvée.",
                          commandInputPlaceHolder: "Chercher société...",
                        }}
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="paymentDate"
                render={({ field }) => {
                  return (
                    <FormItem className="col-span-3 flex flex-col">
                      <FormLabel>Date du paiement</FormLabel>
                      <PopoverCalendar value={field.value} onChange={value => field.onChange(value)} now={now} />
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name="paymentReference"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <FormLabel>Référence du virement</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="adminCommentary"
                render={({ field }) => (
                  <FormItem className="col-span-3">
                    <FormLabel>Commentaire</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <AlertDialogFooter className="mt-4">
              <AlertDialogCancel onClick={onClose} type="button">
                Annuler
              </AlertDialogCancel>
              <AlertDialogAction type="submit" disabled={!isDirty}>
                Valider
              </AlertDialogAction>
            </AlertDialogFooter>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
};
